import * as React from 'react'

import { AppointmentFormTypes, AppointmentUtils, Components } from '@grandvisionhq/appointments'
import { useSubscribeNewsletterViaOptIn } from '@grandvisionhq/bloomreach'
import { Button, Delayed } from '@grandvisionhq/common'
import { Text } from '@grandvisionhq/elements'
import { ComposedForm, FormControl, FormTypes, PhoneNumberInput, Util } from '@grandvisionhq/forms'
import { useIntl } from '@grandvisionhq/state'

import { consents, optIns } from '../../configuration'
import { INTL_DEFAULTS } from '../../configuration/intl'
import { countriesOrder } from '../../configuration/phone-number-input'

const { ConsentsAndOptIns } = Components

import { Types } from '@grandvisionhq/graphql'

const BookAppointmentForm: AppointmentFormTypes.FormComponent = ({
  submitting = false,
  formAlert = null,
  onChange,
  disableSubmit = false,
  beforeSubmitSlot = null,
  ...rest
}) => {
  const { getLabel } = useIntl()
  const { onSubmit: optInNewsletter } = useSubscribeNewsletterViaOptIn()

  const onChangeHandler = React.useCallback(
    (state: FormTypes.ComposedFormState) => {
      onChange?.(state)
    },
    [onChange]
  )

  const handleSubmit = React.useCallback(
    (state: FormTypes.ComposedFormState) => {
      const { formState } = state
      if (
        formState.personalDetails &&
        formState.consentsAndOptIns?.userAgreement?.value === 'true' &&
        formState.consentsAndOptIns?.subscribeNewsletter?.value === 'true' &&
        formState.personalDetails.email?.isValid &&
        formState.personalDetails.firstName?.isValid &&
        formState.personalDetails.lastName?.isValid
      ) {
        optInNewsletter({
          email: formState.personalDetails.email.value,
          firstName: formState.personalDetails.firstName.value,
          lastName: formState.personalDetails.lastName.value,
          mandateText: getLabel('account.optIn.newsletter'),
        })
      }

      rest.onSubmit(state)
    },
    [getLabel, optInNewsletter, rest]
  )

  return (
    <ComposedForm
      {...rest}
      className="book-appointment-form"
      onChange={onChangeHandler}
      onSubmit={handleSubmit}
    >
      <ComposedForm.Part name="personalDetails">
        <FormControl
          {...FormControl.defaultProps}
          id="firstName"
          data-t="personal-details-first-name"
          label={getLabel(
            'appointments.component.bookAppointmentForm.label.personalDetails.firstName'
          )}
          errorMessage={getLabel(
            'appointments.component.bookAppointmentForm.error.personalDetails.firstName'
          )}
        />
        <FormControl
          {...FormControl.defaultProps}
          id="lastName"
          data-t="personal-details-last-name"
          label={getLabel(
            'appointments.component.bookAppointmentForm.label.personalDetails.lastName'
          )}
          errorMessage={getLabel(
            'appointments.component.bookAppointmentForm.error.personalDetails.lastName'
          )}
        />
        <FormControl
          {...FormControl.defaultProps}
          id="email"
          type="email"
          data-t="contact-details-email"
          label={getLabel('appointments.component.bookAppointmentForm.label.contactDetails.email')}
          validations={[Util.validations.isEmailAddress]}
          errorMessage={getLabel(
            'appointments.component.bookAppointmentForm.error.contactDetails.email'
          )}
        />
        <PhoneNumberInput
          {...FormControl.defaultProps}
          id="mobilePhone"
          data-t="contact-details-mobile-phone"
          label={getLabel(
            'appointments.component.bookAppointmentForm.label.contactDetails.mobilePhone'
          )}
          errorMessage={getLabel(
            'appointments.component.bookAppointmentForm.error.contactDetails.mobilePhone'
          )}
          required
          defaultCountry={INTL_DEFAULTS.country}
          localeForTranslations={INTL_DEFAULTS.locale}
          countriesOrder={countriesOrder}
        />
      </ComposedForm.Part>

      <ComposedForm.Part name="consentsAndOptIns">
        <ConsentsAndOptIns
          className="book-appointment-form__spacing-top"
          errorMessage={getLabel('appointments.component.consentsAndOptIns.error')}
          consents={consents}
          optIns={optIns}
          showOptIns={true}
        />
      </ComposedForm.Part>

      {formAlert}
      {beforeSubmitSlot}

      <Button
        type="submit"
        caption={getLabel('appointments.component.bookAppointmentForm.submit')}
        isLoading={submitting}
        disabled={disableSubmit}
        className="book-appointment-form__spacing-top"
      />

      {submitting && (
        <Delayed delay={2500} scrollIntoView className="book-appointment-form__spacing-top">
          <Text
            color="text-secondary"
            align="center"
            className="book-appointment-form__spacing-top"
          >
            {getLabel('appointments.component.bookAppointmentForm.slowLoadingIndication')}
          </Text>
        </Delayed>
      )}
    </ComposedForm>
  )
}

BookAppointmentForm.fromDataToState = (data) => {
  const state: FormTypes.ComposedFormValueState = {}

  const personalDetails: Record<string, string | number> = {
    firstName: data.defaultBillingAddress?.firstName ?? data.personalDetails?.firstName ?? '',
    lastName: data.defaultBillingAddress?.lastName ?? data.personalDetails?.lastName ?? '',
    email: data.email,
    mobilePhone: data.personalDetails?.phone ?? '',
  }

  return Util.mergeComposedFormValueState(state, {
    personalDetails: Util.fieldsToFormState(personalDetails),
  })
}

BookAppointmentForm.getFormGroups = () => ['personalDetails', 'consentsAndOptIns']

BookAppointmentForm.fromStateToData = (state, { intl }) => {
  const data = Util.getValuesFromComposedState(state)
  const consentsAreDisplayed = data.consentsAndOptIns && 'userAgreement' in data.consentsAndOptIns
  const optInsAreDisplayed =
    data.consentsAndOptIns && 'subscribeNewsletter' in data.consentsAndOptIns

  const result: AppointmentFormTypes.FormStateData = {
    personalDetails: {
      firstName: data.personalDetails?.firstName ?? '',
      lastName: data.personalDetails?.lastName ?? '',
      email: data.personalDetails?.email ?? '',
      phone: data.personalDetails?.mobilePhone ?? '',
      billingAddress: {
        firstName: data.personalDetails?.firstName ?? '',
        lastName: data.personalDetails?.lastName ?? '',
      },
    },
    child: null,
    consents: consentsAreDisplayed
      ? [
          {
            consentName: 'privacy-policy',
            value: data.consentsAndOptIns?.userAgreement === 'true',
            uiType: Types.UIType.CHECKBOX,
            literalText: intl.getFormattedLabel(
              AppointmentUtils.getLiteralText('userAgreement', consents) ?? '',
              { parseHTML: false, parseLink: true }
            ),
          },
        ]
      : null,
    optins: optInsAreDisplayed
      ? [
          {
            id: 'subscribeNewsletter',
            value: data.consentsAndOptIns?.subscribeNewsletter === 'true',
            uiType: Types.UIType.CHECKBOX,
            literalText: intl.getLabel('account.optIn.newsletter'),
          },
        ]
      : null,
    customerComment: null,
    preferredChannelOfCommunication: null,
  }

  return result
}

export default BookAppointmentForm
